@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fixed-button {
  position: fixed; /* Fisso rispetto alla finestra */
  top: 10px; /* Distanza dall'alto */
  left: 10px; /* Distanza dal lato sinistro */
  z-index: 1000; /* Assicurati che stia sopra ad altri elementi */
}

@media (max-width: 768px) {
  .fixed-button {
    right: 10px; /* Mantieni il bottone a destra su schermi piccoli */
    left: auto;  /* Assicurati che non venga spostato a sinistra */
    margin-left: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
